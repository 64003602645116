import { Component, input } from '@angular/core';
import { Breadcrumb } from 'src/app/core/services/breadcrumb.service';

@Component({
  selector: 'liv-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  readonly breadcrumbs = input.required<Breadcrumb[]>();
  readonly size = input<'sm' | 'md' | 'lg'>('sm');
}
