import { environment } from 'src/environments/environment';

export type Menu = {
  name: string;
  enabled: boolean;
  icon: string;
  isNew?: boolean;
  link: {
    url: string;
    target?: '_self' | '_blank';
  };
};

export const menu: Menu[] = [
  {
    name: 'Início',
    enabled: true,
    icon: 'inicio.svg',
    link: {
      url: 'inicio'
    }
  },
  {
    name: 'Materiais',
    enabled: true,
    icon: 'materiais.svg',
    link: {
      url: 'materiais/gerais'
    }
  },
  {
    name: 'Materiais Implantação',
    enabled: false,
    icon: 'implantacao-2022.svg',
    link: {
      url: 'materiais/implantacao'
    }
  },
  {
    name: 'Portfólio',
    enabled: true,
    icon: 'camera.svg',
    link: {
      url: 'portfolio'
    }
  },
  {
    name: 'Percurso Pedagógico',
    enabled: false,
    icon: 'percurso-pedagogico.svg',
    link: {
      url: 'percurso'
    }
  },
  {
    name: 'Formações',
    enabled: true,
    icon: 'formacoes.svg',
    link: {
      url: 'formacoes'
    }
  },
  {
    name: 'Biblioteca',
    enabled: true,
    isNew: true,
    icon: 'menu-book.svg',
    link: {
      url: 'biblioteca'
    }
  },
  {
    name: 'Ciclos',
    enabled: !environment.production,
    isNew: true,
    icon: 'inicio.svg',
    link: {
      url: 'ciclos-preview-2024'
    }
  },
  // {
  //   name: 'Treinamentos',
  //   enabled: true,
  //   icon: 'treinamentos.svg',
  //   link: {
  //     url: 'treinamentos'
  //   }
  // },
  {
    name: 'Soluções de Marketing',
    enabled: true,
    icon: 'campaign.svg',
    link: {
      url: 'https://sites.google.com/inteligenciadevida.com.br/solucoesdemarketingliv/',
      target: '_blank'
    }
  },
  {
    name: 'FAQ',
    enabled: true,
    icon: 'faq.svg',
    link: {
      url: 'https://suporte.inteligenciadevida.com.br/hc/pt-br',
      target: '_blank'
    }
  }
];
