@if (breadcrumbs()?.length) {
  <nav aria-label="Breadcrumb">
    <ol role="list" class="hidden items-center space-x-1.5 md:flex">
      @for (breadcrumb of breadcrumbs(); track $index; let last = $last) {
        <li class="flex items-center !gap-1.5">
          @if (!last) {
            <a
              class="block truncate font-semibold leading-normal text-neutral-low-dark transition-colors duration-150 hover:text-neutral-low-pure"
              [ngClass]="{
                'text-sm': size() === 'sm',
                'text-base': size() === 'md',
                'text-lg': size() === 'lg'
              }"
              [routerLink]="[breadcrumb.path]"
              [queryParams]="breadcrumb.params"
            >
              <span>
                {{ breadcrumb.label }}
              </span>
            </a>

            <!-- Ícone de seta somente se não for o último breadcrumb -->
            <ng-icon
              name="featherChevronRight"
              size="20"
              class="flex-shrink-0 text-neutral-low-dark"
            />
          } @else {
            <span
              class="font-medium leading-normal text-brand-primary-pure"
              [ngClass]="{
                'text-sm': size() === 'sm',
                'text-base': size() === 'md',
                'text-lg': size() === 'lg'
              }"
              >{{ breadcrumb.label }}</span
            >
          }
        </li>
      }
    </ol>

    <div class="md:hidden">
      @if (breadcrumbs().length > 1) {
        <a
          class="flex items-center !gap-1 text-brand-primary-pure transition-colors duration-150 hover:text-brand-primary-dark"
          [routerLink]="[breadcrumbs()[breadcrumbs().length - 2].path]"
          [queryParams]="breadcrumbs()[breadcrumbs().length - 2].params"
        >
          <ng-icon
            name="featherChevronLeft"
            size="16"
            class="flex-shrink-0 text-current"
          ></ng-icon>

          <span
            class="inline-block w-full truncate leading-normal"
            [ngClass]="{
              'text-sm': size() === 'sm',
              'text-base': size() === 'md',
              'text-lg': size() === 'lg'
            }"
          >
            {{ breadcrumbs()[breadcrumbs().length - 2].label }}
          </span>
        </a>
      } @else {
        <span
          class="font-medium leading-normal text-brand-primary-pure"
          [ngClass]="{
            'text-sm': size() === 'sm',
            'text-base': size() === 'md',
            'text-lg': size() === 'lg'
          }"
          >{{ breadcrumbs()[0].label }}</span
        >
      }
    </div>
  </nav>
}
